import { useRef, RefObject, useCallback, useState, useMemo } from 'react'
import { Token } from '@pancakeswap/sdk'
import { Text, Button, TrashIcon, IconButton, LinkExternal, Input, Link } from '@pancakeswap/uikit'
import styled from 'styled-components'
import Row, { RowBetween, RowFixed } from 'components/Layout/Row'
import { useToken } from 'hooks/Tokens'
import { useRemoveUserAddedToken } from 'state/user/hooks'
import useUserAddedTokens from 'state/user/hooks/useUserAddedTokens'
import { CurrencyLogo } from 'components/Logo'
import { getBlockExploreLink, isAddress } from 'utils'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import Column, { AutoColumn } from '../Layout/Column'
import ImportRow from './ImportRow'
import { CurrencyModalView } from './types'

const Wrapper = styled.div`
  width: 100%;
  minwidth: '450px';
  height: calc(100% - 60px);
  position: relative;
  padding-bottom: 60px;
`

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default function ManageTokens({
  setModalView,
  setImportToken,
}: {
  setModalView: (view: CurrencyModalView) => void
  setImportToken: (token: Token) => void
}) {
  const { chainId } = useActiveChainId()

  const { t } = useTranslation()

  const [searchQuery, setSearchQuery] = useState<string>('')

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
  }, [])

  // if they input an address, use it
  const searchToken = useToken(searchQuery)

  // all tokens for local list
  const userAddedTokens: Token[] = useUserAddedTokens()
  const removeToken = useRemoveUserAddedToken()

  const handleRemoveAll = useCallback(() => {
    if (chainId && userAddedTokens) {
      userAddedTokens.forEach((token) => {
        return removeToken(chainId, token.address)
      })
    }
  }, [removeToken, userAddedTokens, chainId])

  const tokenList = useMemo(() => {
    return (
      chainId &&
      userAddedTokens.map((token) => (
        <RowBetween
          key={token.address}
          width="100%"
          marginTop="12px"
          padding="0 16px"
          borderRadius="24px"
          style={{
            background: 'linear-gradient(180deg, rgba(244, 118, 33, 0.10) -66.22%, rgba(244, 118, 33, 0.05) 85.46%)',
            border: '2px solid #F4762133',
          }}
        >
          <RowFixed>
            <CurrencyLogo currency={token} size="20px" />
            <Link external href={getBlockExploreLink(token.address, 'address', chainId)} color="white" ml="10px">
              {token.symbol}
            </Link>
          </RowFixed>
          <RowFixed>
            <IconButton
              style={{ background: 'none', padding: 0, backgroundColor: 'transparent' }}
              variant="text"
              onClick={() => removeToken(chainId, token.address)}
            >
              <TrashIcon />
            </IconButton>
            <LinkExternal href={getBlockExploreLink(token.address, 'address', chainId)} color="primary" />
          </RowFixed>
        </RowBetween>
      ))
    )
  }, [userAddedTokens, chainId, removeToken])

  const isAddressValid = searchQuery === '' || isAddress(searchQuery)

  return (
    <Wrapper>
      <Column style={{ width: '100%', flex: '1 1', marginTop: '20px' }}>
        <AutoColumn gap="14px">
          <Row px="4px !important">
            <Input
              style={{
                background: 'transparent',
                borderRadius: '24px',
                border: '1px solid #25282C',
              }}
              id="token-search-input"
              scale="lg"
              placeholder="Input address"
              value={searchQuery}
              autoComplete="off"
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={handleInput}
              isWarning={!isAddressValid}
            />
          </Row>
          {!isAddressValid && <Text color="failure">{t('Enter valid token address')}</Text>}
          {searchToken && (
            <ImportRow
              token={searchToken}
              showImportView={() => setModalView(CurrencyModalView.importToken)}
              setImportToken={setImportToken}
              style={{ height: 'fit-content', marginBottom: '12px' }}
            />
          )}
        </AutoColumn>
        {tokenList}
        <Footer>
          <Text
            style={{
              fontWeight: '500',
            }}
            color="white"
          >
            {userAddedTokens?.length} {userAddedTokens.length === 1 ? t('Imported Token') : t('Imported Tokens')}
          </Text>
          {userAddedTokens.length > 0 && (
            <Button variant="tertiary" color="#fde53a !important" rounded onClick={handleRemoveAll}>
              {t('Clear all')}
            </Button>
          )}
        </Footer>
      </Column>
    </Wrapper>
  )
}
