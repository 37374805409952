import { getAddress } from '@ethersproject/address'
import memoize from 'lodash/memoize'
import { Token } from '@pancakeswap/sdk'
import chainName from '../config/constants/chainName'
import { TOKEN_LOGO_S3_BUCKET_NAME } from '@icecreamswap/constants'

const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token && chainName[token.chainId]) {
      return `/images/${token.chainId}/tokens/${getAddress(token.address)}.png`
    }
    return null
  },
  (t) => `${t.chainId}#${t.address}`,
)

export default getTokenLogoURL
