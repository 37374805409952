import { useState } from 'react'
import { Token } from '@pancakeswap/sdk'
import { Button, ButtonMenu, ButtonMenuItem, Flex, ModalBody } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { TokenList } from '@uniswap/token-lists'
import { useTranslation } from '@pancakeswap/localization'
import ManageLists from './ManageLists'
import ManageTokens from './ManageTokens'
import { CurrencyModalView } from './types'
import { BaseButtonProps, variants } from '@pancakeswap/uikit/src/components/Button/types'
import { PolymorphicComponent } from '@pancakeswap/uikit/src/util/polymorphic'

const StyledButtonMenu = styled(ButtonMenu)`
  width: 100%;
`

export default function Manage({
  setModalView,
  setImportList,
  setImportToken,
  setListUrl,
}: {
  setModalView: (view: CurrencyModalView) => void
  setImportToken: (token: Token) => void
  setImportList: (list: TokenList) => void
  setListUrl: (url: string) => void
}) {
  const [showLists, setShowLists] = useState(true)
  const { t } = useTranslation()

  return (
    <ModalBody>
      {/* <Flex flexDirection='row' justifyContent='space-between' style={{
        marginBottom: '32px',
        gap: '4px',
      }}>

        {
          showLists ?

            <ButtonMenuItem onClick={() => setShowLists(true)} width="50%" style={{ backgroundColor: 'gradientCra', color: 'white' }}>
              {t('Lists')}
            </ButtonMenuItem>
            :
            <button onClick={() => setShowLists(true)} style={{
              width: '50%',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#3a3b3e',
              color: 'gray',
              cursor: 'pointer'
            }} type='button'>{t('Lists')}</button>
        }
        {
          !showLists ?
            <ButtonMenuItem onClick={() => setShowLists(false)} width="50%" style={{ backgroundColor: 'transparent', color: 'white' }}>
              {t('Tokens')}
            </ButtonMenuItem>
            :
            <button onClick={() => setShowLists(false)} style={{
              width: '50%',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: '#3a3b3e',
              color: 'gray',
              cursor: 'pointer'
            }} type='button'>{t('Tokens')}</button>
        }
      </Flex> */}
      {/* {showLists ? (
        <ManageLists setModalView={setModalView} setImportList={setImportList} setListUrl={setListUrl} />
      ) : ( */}
      <ManageTokens setModalView={setModalView} setImportToken={setImportToken} />
      {/* )} */}
    </ModalBody>
  )
}
