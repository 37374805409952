import { ChainId, Currency, Token } from '@pancakeswap/sdk'
import { Text, QuestionHelper, Flex } from '@pancakeswap/uikit'
import styled from 'styled-components'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { useTranslation } from '@pancakeswap/localization'

import { SUGGESTED_BASES } from 'config/constants/exchange'
import { AutoColumn } from '../Layout/Column'
import { AutoRow } from '../Layout/Row'
import { CurrencyLogo } from '../Logo'
import { CommonBasesType } from './types'

const ButtonWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ disable }) => (disable ? 'transparent' : '#ffffff0d')};
  border-radius: 18px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ disable }) => !disable && '#ffffff0d'};
  }
  background-color: ${({ disable }) => disable && '#ffffff0d'};
  opacity: ${({ disable }) => disable && '0.4'};
`

const RowWrapper = styled.div`
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
  commonBasesType,
}: {
  chainId?: ChainId
  commonBasesType
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const native = useNativeCurrency()
  const { t } = useTranslation()
  const pinTokenDescText = commonBasesType === CommonBasesType.SWAP_LIMITORDER ? t('Common tokens') : t('Common bases')

  return (
    <AutoColumn gap="md" style={{ paddingBottom: '14px' }}>
      <AutoRow>
        <Text fontSize="14px" fontWeight={500} color="#fbfbfb">
          {pinTokenDescText}
        </Text>
        {commonBasesType === CommonBasesType.LIQUIDITY && (
          <QuestionHelper text={t('These tokens are commonly paired with other tokens.')} ml="4px" />
        )}
      </AutoRow>
      <Flex
        padding="6px"
        style={{
          border: '1px solid #262626',
          borderRadius: '24px',
          overflowX: 'auto',
        }}
      >
        <RowWrapper>
          <ButtonWrapper>
            <BaseWrapper
              onClick={() => {
                if (!selectedCurrency || !selectedCurrency.isNative) {
                  onSelect(native)
                }
              }}
              disable={selectedCurrency?.isNative}
              style={{ backgroundColor: '#FDE53A4d', border: '1px solid #FDE53A' }}
            >
              <CurrencyLogo currency={native} style={{ marginRight: 10 }} size="20px" />
              <Text fontSize="12px" fontWeight={600} color="#fff !important">
                {native?.symbol}
              </Text>
            </BaseWrapper>
          </ButtonWrapper>
          {(chainId ? SUGGESTED_BASES[chainId] || [] : []).map((token: Token) => {
            const selected = selectedCurrency?.equals(token)
            return (
              <ButtonWrapper key={`buttonBase#${token.address}`}>
                <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected}>
                  <CurrencyLogo currency={token} style={{ marginRight: 10, borderRadius: '50%' }} size="20px" />
                  <Text fontSize="12px" fontWeight={600} color="#b4b4b4">
                    {token.symbol}
                  </Text>
                </BaseWrapper>
              </ButtonWrapper>
            )
          })}
        </RowWrapper>
      </Flex>
    </AutoColumn>
  )
}
